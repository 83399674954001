export const en = {
  caption: {
    all_request: 'All Maintenance Request',
    detail_request: 'Details Maintenance Request',
    card_detail: {
      location: 'Location',
      description: 'Description',
      overal_rates: 'Overal Rates',
      submitter: 'Submitter Information',
    },
    attachments: 'Attachments',
    photos: 'Photos',
    project_approved: 'Project Approved',
    maximum_evaluator: 'Please enter the maximum evaluator for the Project',
    evaluator_assigned: 'Evaluator Assigned',
    project_status: 'Project Status',
    all_evaluation_request: 'All Evaluation Request',
    detail_evaluation_request: 'Detail Evaluation Request',
    evaluation: 'Evaluation',
    project_evaluated: 'Project Evaluated',
    my_evaluation_task: 'My Evaluation Task',
    step: 'Step',
    all_mosques_evaluated: 'All Mosques Evaluated',
    detail_mosques_evaluated: 'Details Mosque Evaluated',
    no_name_mosque: '(No Name Mosque)',
    general: 'General',
    location_pinpoint: 'Location Pin Point',
    saudi_government: 'Saudi Government',
  },
  form: {
    evaluator_for_project: {
      label: 'Evaluator for the Project',
      placeholder: 'e.g 10 Evaluator',
      required: 'You must enter maximum Evaluator',
      min: 'Please enter the Evaluator greater than 0',
    },
    mosque_name: {
      label: 'Mosque Name',
      required: 'Mosque Name is required!',
    },
    locations: {
      label: 'Locations',
    },
    request_date: {
      label: 'Request Date',
    },
    evaluate_date: {
      label: 'Evaluate Date',
    },
    selection: {
      label: 'Select your option',
      required: 'You must select one of options!',
      options: {
        very_poor: 'Very Poor (1-2)',
        poor: 'Poor (3-4)',
        fair: 'Fair (5-6)',
        good: 'Good (7-8)',
        excellent: 'Excellent (9-10)',
        necessary: 'Necessary',
        unnecessary: 'Unnecessary',
      },
    },
    image: {
      label: 'Upload Photo',
      required: 'You must add minimun one photo!',
    },
    prayer_hall: {
      label: 'Prayer Hall',
    },
    wet_areas: {
      label: 'Wet Areas',
    },
    minaret: {
      label: 'Minaret',
    },
    exterior_walls: {
      label: 'Exterior Walls',
    },
    foundation: {
      label: 'Foundation',
    },
    roof_inspection_and_repair: {
      label: 'Roof Inspections and Repair',
    },
    floor: {
      label: 'Floor',
    },
    walls_and_ceilings: {
      label: 'Walls and Ceilings',
    },
    windows_and_doors: {
      label: 'Windows and Doors',
    },
    electrical_system: {
      label: 'Electrical System',
    },
    fire_safety: {
      label: 'Fire Safety',
    },
    lighting: {
      label: 'Lighting',
    },
    finishes_and_decorations: {
      label: 'Finishes and Decorations',
    },
    temperature: {
      label: 'Temperature',
    },
    architectural: {
      label: 'Architectural',
    },
    operational: {
      label: 'Operational',
    },
    humidity: {
      label: 'Humidity',
    },
    special_water_conditions: {
      label: 'Special Water Conditions',
    },
    factor_type: {
      label: 'Factor',
    },
    images: {
      label: 'Images',
    },
    expected_cost: {
      label: 'What is the expected cost?',
      placeholder: 'e.g 100',
      min_1: 'Must be greater than 1',
      required: 'Expected cost is required',
    },
    name: {
      label: 'Full Name',
      required: 'Fullname is required!',
    },
    phone_number: {
      label: 'Phone Number',
      required: 'Phone Number is required!',
      length: 'Phone number must be 9 digits',
    },
    relation_for_mosque: {
      label: 'Relation with The Mosque',
      required: 'Relation with The Mosque is required!',
      options: {
        certified_resident: 'Certified Resident',
        imam: 'Imam',
        muezzin: 'Muezzin',
        observer: 'Observer',
        prayer: 'Prayer',
      },
    },
    mosque_type: {
      label: 'Mosque Type',
      required: 'Mosque Type is required!',
    },
    created_date: {
      label: 'Created Date',
    },
    mosque_code: {
      label: 'Mosque Code',
      required: 'Mosque Code is required!',
      placeholder: 'e.g: Ry_0001',
    },
    city_village: {
      label: 'City Village',
      required: 'City Village is required!',
    },
    district: {
      label: 'District',
      required: 'District is required!',
    },
    street_name: {
      label: 'Street Name',
      required: 'Street Name is required!',
    },
    mosque_id: {
      label: 'Mosque ID',
      required: 'Mosque ID is required!',
    },
    mosque_national_code: {
      label: 'Mosque National Code',
      required: 'Mosque National Code is required!',
      invalid: 'Format value must be e.g: 01-00221',
      placeholder: 'e.g: 01-00221',
    },
    iso_code: {
      label: 'ISO Code',
      placeholder: '01',
      required: 'ISO Code is required!',
      invalid: 'ISO Code must be e.g: 01',
    },
  },
  button: {
    approve_request: 'Approve Request',
    reject_request: 'Reject Request',
    apply_for_evaluation: 'Apply for Evaluation',
    archive_evaluation: 'Archive this Evaluation',
    add_photos: 'Add Photos',
    accept_request: 'Accept Request',
    approve_evaluation: 'Approve Evaluation',
    reject_evaluation: 'Reject Evaluation',
    save_changes: 'Save Changes',
  },
};
