export const ar = {
  title: {
    project_marketplace: 'سوق المسجد',
    status: 'الحالة',
    tender_request_status: 'طلب الحالة',
    request_detail: 'تفاصيل الطلب',
    technical_offer: 'العرض الفني',
    financial_offer: 'العرض المالي',
    no_files_uploaded: 'لم يتم تحميل ملفات',
  },
  button: {
    add_new_item: 'أضف منتج جديد',
    submit: 'إضافة',
    apply_tender_request: 'تأكيد',
    approve_request: 'الموافقة على الطلب',
    reject_request: 'رفض الطلب',
    view_detail: 'عرض التفاصيل',
  },
  form: {
    name: {
      label: 'اسم العنصر',
      required: 'اسم العنصر مطلوب',
    },
    amount_needed: {
      label: 'عدد الوحدات المطلوبة',
      min_1: 'يجب أن يكون أكبر من 1',
      required: 'عدد الوحدات مطلوب',
    },
    provide_items: {
      label: 'توفر العنصر',
      required: 'توفر العنصر مطلوب',
    },
    price: {
      label: 'السعر',
      min_1: 'يجب أن يكون أكبر من 1',
      required: 'السعر مطلوب',
    },
    vendor_type: {
      label: 'حدد النوع ',
      required: 'نوع المقاول مطلوب',
      options: {
        MANUAL_VENDOR: 'اختيار المقاول',
        WITHOUT_VENDOR: 'بدون مقاول',
        PRODUCT_TENDER: ' مناقصة',
      },
    },
    vendor_id: {
      label: 'حدد المقاول',
      required: 'المقاول مطلوب',
    },
    specification: {
      label: 'أدخل المواصفات',
      label_2: 'المواصفات',
      required: 'المواصفات مطلوبة',
    },
    start_date: {
      label: 'تاريخ البدء',
      required: 'تاريخ البدء مطلوب',
    },
    deadline_date: {
      label: 'تاريخ الموعد النهائي',
      required: ' تاريخ الموعد النهائي مطلوب',
    },
    delivery_date: {
      label: 'تاريخ التسليم أو الوصول',
      required: 'تاريخ التسليم مطلوب',
    },
    insurance_details: {
      label: 'تفاصيل التأمين',
      required: 'تفاصيل التأمين مطلوبة',
    },
    contract_agreement: {
      label: 'اتفاقية العقد',
      required: 'اتفاق العقد مطلوب',
      max_1: 'يجب أن يكون أقل من 1',
    },
    contract_signature: {
      label: 'توقيع العقد',
      required: 'توقيع العقد مطلوب',
      max_1: 'يجب أن يكون أقل من 1',
    },
    proposed_price: {
      label: 'السعر المقترح',
      required: 'السعر المقترح مطلوب',
      min_1: 'يجب أن يكون أكبر من 1',
    },
    proposed_amount_needed: {
      label: 'العنصر المقترح ',
      required: 'العنصر المقترح مطلوب ',
      min_1: 'يجب أن يكون أكبر من 1',
    },
    technical_offer: {
      label: 'العرض الفني',
      required: 'العرض الفني مطلوب',
      max_1: 'يجب أن يكون أقل من ملف واحد',
      max_5: 'يجب أن يكون أقل من 5 ملفات',
    },
    financial_offer: {
      label: 'العرض المالي',
      required: 'العرض المالي مطلوب',
    },
  },
};
