export const ar = {
  form: {
    user_id: {
      label: 'حدد المانح',
      required: 'يجب عليك تحديد متبرع واحد',
    },
    balance: {
      label: 'توازن',
      required: 'يجب أن تملأ الرصيد',
      min_1: 'يجب أن تملأ الرصيد يكون أكبر من 0',
    },
  },
};
