export const en = {
  button: {
    view: 'View',
    delete_email: 'Delete email',
    edit_email: 'Edit email',
  },
  headers: {
    sender_email: 'Sender email',
    name: 'Name',
    sent_at: 'Sent at',
  },
  title: {
    all_emails: 'All emails',
  },
  form: {
    email: {
      label: 'Your email',
    },
    senderEmail: {
      label: 'Sender email',
    },
    name: {
      label: 'Name',
    },
    location: {
      label: 'Your location',
    },

    how_did_you_find: {
      label: 'How did you find Ommar',
    },
    suggestion: {
      label: 'Any suggestion for Ommar',
    },
    upload: {
      label: 'Upload any files that help us understand your anilities better ',
    },
    buttons: {
      submit: 'Submit Form',
    },
  },
};
