import { io, Socket } from 'socket.io-client';
import { useMemo, useEffect, useReducer, useCallback } from 'react';

import { HOST_API } from 'src/config-global';
import { ActionMapType } from 'src/auth/types';
import { useAuthContext } from 'src/auth/hooks';
import {
  TSendMessage,
  TCreateTicket,
  TForwardedTicket,
  TNotificationEntity,
  TConversationEntity,
  TSocketMessageEntity,
} from 'src/socket-ticket/type';

import { SocketContext } from './socket-context';

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  CONVERSATIONS = 'CONVERSATIONS',
  // MESSAGES = 'MESSAGES',
  NOTIFICATIONS = 'NOTIFICATIONS',
}

type Payload = {
  [Types.INITIAL]: {
    loading: boolean;
    socket: Socket;
  };
  [Types.NOTIFICATIONS]: {
    loading: boolean;
    notifications: TNotificationEntity[];
  };
  [Types.CONVERSATIONS]: {
    loading: boolean;
    conversations: TConversationEntity[];
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

type TicketStateType = {
  loading: boolean;
  socket: Socket | null;
  conversations: TConversationEntity[];
  notifications: TNotificationEntity[];
};

// type TSocketStatus = { type: string; message: string };

// ----------------------------------------------------------------------

const initialState: TicketStateType = {
  socket: null,
  loading: true,
  conversations: [],
  notifications: [],
};

const reducer = (state: TicketStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      ...state,
      loading: action.payload.loading,
      socket: action.payload.socket,
    };
  }

  if (action.type === Types.NOTIFICATIONS) {
    return {
      ...state,
      loading: action.payload.loading,
      notifications: action.payload.notifications,
    };
  }

  if (action.type === Types.CONVERSATIONS) {
    return {
      ...state,
      loading: action.payload.loading,
      conversations: action.payload.conversations,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function SocketProvider({ children }: Props) {
  const { user } = useAuthContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (user && accessToken && HOST_API) {
        dispatch({
          type: Types.INITIAL,
          payload: {
            loading: false,
            socket: io(HOST_API, {
              withCredentials: true,
              extraHeaders: {
                Authorization: accessToken ?? '',
                'x-chosen-role': user.activeRole,
              },
            }),
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [user]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleInitializeSocket = useCallback(() => initialize(), [initialize]);

  const handleDisconnectSocket = useCallback(() => {
    if (state.socket) {
      state.socket.disconnect();
    }
  }, [state]);

  const listenException = useCallback(async () => {
    if (state.socket && state.socket.connected) {
      state.socket.on('exception', ({ message }) => {
        console.error('exception', message.message);
      });
    }
  }, [state.socket]);

  const listenSuccess = useCallback(async () => {
    if (state.socket && state.socket.connected) {
      state.socket.on('success', (res) => {
        console.info('success', typeof res === 'object' ? res.message : res);
      });
    }
  }, [state.socket]);

  const listenIncomingMessage = useCallback(() => {
    if (state.socket) {
      state.socket.on('incoming_message', (res) => {
        const response: {
          incoming_message: TSocketMessageEntity;
          notification_sent: TNotificationEntity[];
        } = res;

        const existingConversationIndex = state.conversations.findIndex(
          (conversation) => conversation.room_id === response.incoming_message.room_id
        );

        if (existingConversationIndex !== -1) {
          const updatedConversations = [...state.conversations];

          const existingMessageIndex = updatedConversations[
            existingConversationIndex
          ].messages.findIndex((msg) => msg.message_id === response.incoming_message.message_id);

          if (existingMessageIndex === -1) {
            updatedConversations[existingConversationIndex].messages.push(
              response.incoming_message
            );
          }

          dispatch({
            type: Types.CONVERSATIONS,
            payload: {
              loading: false,
              conversations: updatedConversations,
            },
          });
        } else {
          const newConversation: TConversationEntity = {
            room_id: response.incoming_message.room_id,
            messages: [response.incoming_message],
          };

          dispatch({
            type: Types.CONVERSATIONS,
            payload: {
              loading: false,
              conversations: [...state.conversations, newConversation],
            },
          });
        }
      });

      state.socket.on('exception', ({ message }) => {
        console.error('exception', message.message);
      });

      state.socket.on('success', (res) => {
        console.info('success', typeof res === 'object' ? res.message : res);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.socket, state.conversations]);

  const listenNewNotification = useCallback(() => {
    if (state.socket) {
      state.socket.on('new_notification', (res: TNotificationEntity) => {
        if (res) {
          dispatch({
            type: Types.NOTIFICATIONS,
            payload: {
              loading: false,
              notifications: [{ ...res, title: res.title }, ...state.notifications],
            },
          });
        }
      });
    }
  }, [state.socket, state.notifications]);

  const handleAddTicket = useCallback(
    async (payload: TCreateTicket) => {
      if (state.socket && state.socket.connected) {
        state.socket.emit('create_ticket', payload);

        listenException();
        listenSuccess();
      }
    },
    [state.socket, listenException, listenSuccess]
  );

  const handleJoinTicketRoom = useCallback(
    async (room_id: string) => {
      if (state.socket && state.socket.connected) {
        state.socket.emit('join_ticket_room', {
          room_id,
        });

        listenException();
        listenSuccess();
      }
    },
    [state.socket, listenSuccess, listenException]
  );

  const handleSendMessage = useCallback(
    async (payload: TSendMessage) => {
      if (state.socket && state.socket.connected) {
        state.socket.emit('send_message', payload);
      }
    },
    [state]
  );

  const handleReadNotif = useCallback(
    (notif_id: string) => {
      const findNotif = state.notifications.find((v) => v.id === notif_id);

      if (findNotif) {
        const changeReadStatus = state.notifications.map((v) => ({
          ...v,
          read_status: v.id === notif_id ? true : v.read_status,
        }));

        dispatch({
          type: Types.NOTIFICATIONS,
          payload: {
            loading: false,
            notifications: changeReadStatus,
          },
        });
      }
    },
    [state.notifications]
  );

  const handleMarkAllReadNotif = useCallback(() => {
    const readAll = state.notifications.map((v) => ({
      ...v,
      read_status: true,
    }));

    dispatch({
      type: Types.NOTIFICATIONS,
      payload: {
        loading: false,
        notifications: readAll,
      },
    });
  }, [state]);

  const handleInitMessage = useCallback(
    (room_id: string, payload: TSocketMessageEntity[]) => {
      const existingConversationIndex = state.conversations.findIndex(
        (conversation) => conversation.room_id === room_id
      );

      if (existingConversationIndex !== -1) {
        const updatedConversations = [...state.conversations];

        updatedConversations[existingConversationIndex].messages = payload;

        dispatch({
          type: Types.CONVERSATIONS,
          payload: {
            loading: false,
            conversations: updatedConversations,
          },
        });
      } else {
        const newConversation: TConversationEntity = {
          room_id,
          messages: payload,
        };

        dispatch({
          type: Types.CONVERSATIONS,
          payload: {
            loading: false,
            conversations: [...state.conversations, newConversation],
          },
        });
      }
    },
    [state.conversations]
  );

  const handleForwardedTicket = useCallback(
    (payload: TForwardedTicket) => {
      if (state.socket && state.socket.connected) {
        const emitPayload = {
          ticket_id: payload.ticket_id,
          target: payload.department === 'PROJECT' ? ['OPERATOR'] : [payload.target],
        };

        if (payload.status === 'FORWARDED') {
          state.socket.emit('forward_ticket', emitPayload);
        }

        if (payload.status === 'CLOSED') {
          state.socket.emit('close_ticket', emitPayload);
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [state.socket]
  );

  const handleAssignResolvedTicket = useCallback(
    (payload: TForwardedTicket) => {
      if (state.socket && state.socket.connected) {
        const emitPayload = {
          ticket_id: payload.ticket_id,
          target: [payload.target],
        };

        if (payload.status === 'ASSIGNED') {
          state.socket.emit('assign_ticket', emitPayload);
        }

        if (payload.status === 'RESOLVED') {
          state.socket.emit('resolve_ticket', emitPayload);
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [state.socket]
  );

  useEffect(() => {
    if (state.socket) {
      state.socket.connect();
    }
  }, [state.socket]);

  const memoizedValue = useMemo(
    () => ({
      socket: state.socket,
      loading: state.loading,
      conversations: state.conversations,
      notifications: state.notifications,
      handleInitializeSocket,
      handleDisconnectSocket,
      handleAddTicket,
      handleJoinTicketRoom,
      handleSendMessage,
      handleInitMessage,
      listenIncomingMessage,
      listenNewNotification,
      handleReadNotif,
      handleMarkAllReadNotif,
      handleForwardedTicket,
      handleAssignResolvedTicket,
    }),
    [
      state,
      handleInitializeSocket,
      handleDisconnectSocket,
      handleAddTicket,
      handleJoinTicketRoom,
      handleInitMessage,
      handleSendMessage,
      listenIncomingMessage,
      listenNewNotification,
      handleReadNotif,
      handleMarkAllReadNotif,
      handleForwardedTicket,
      handleAssignResolvedTicket,
    ]
  );

  return <SocketContext.Provider value={memoizedValue}>{children}</SocketContext.Provider>;
}
