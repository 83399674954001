export const ar = {
  volunteer: {
    success_applied: 'تم التطبيق بنجاح',
    success_applied_responded: 'استجابة التطبيق ناجحة',
  },
  campaign_edit: {
    ask_changes: 'تم إرسال طلب التغييرات الخاص بك بنجاح',
    respond_changes: 'تم تحرير الحملة بنجاح',
  },
  governorates: {
    success_delete: 'تم حذف المحافظة',
    success_create: 'تم إنشاء محافظة جديدة',
    success_update: 'تم تحديث المحافظة',
  },
  regions: {
    success_create: 'تم إنشاء منطقة جديدة',
    success_update: 'تم تحديث المنطقة المحيطة',
  },
  mosque_directories: {
    success_created_directories: 'تم إنشاء دليل مسجد جديد بنجاح',
  },
  product_tender: {
    success_vendor_apply: 'تم إرسال طلبك بنجاح',
    success_vendor_responded: {
      ACCEPT: 'تم قبول طلب البائع',
      REJECT: 'تم رفض طلب البائع',
    },
  },
};
