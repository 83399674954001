import axios, { AxiosRequestConfig } from 'axios';

import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  // Ommar
  project: {
    create: '/projects/create',
    update: '/projects/update',
    list: '/projects',
    mine: '/projects/mine',
    edit_request: {
      ask_changes: '/projects/edit-requests/ask-changes',
      respond_changes: '/projects/edit-requests/respond-changes',
    },
  },
  campaign: {
    create: '/campaigns/create',
    update: '/campaigns/update',
    list: '/campaigns',
    mine: '/campaigns/mine',
    edit_request: {
      ask_changes: '/campaigns/edit-requests/ask-changes',
      respond_changes: '/campaigns/edit-requests/respond-changes',
    },
    milestones: {
      bank_list: '/campaigns/milestones/banks',
      add_process: '/campaigns/milestones/add-process',
      respond_progress: '/campaigns/milestones/respond-process',
      upload_invoice: '/campaigns/milestones/upload-invoice',
    },
    vendor_payments: {
      upload_payments: '/campaigns/vendor-payments/upload-payment',
    },
  },
  comment: {
    add: '/comment/create',
    reply: '/comment/reply',
    project_comment_reply: '/comment/project/reply',
    delete: '/comment/delete',
    delete_reply: '/comment/reply/delete',
    update_reply: '/comment/reply/update',
  },
  projectItems: {
    create: '/project-items/create',
    update: '/project-items/update',
    edit_request: {
      ask_changes: '/project-items/edit-requests/ask-changes',
      respond_changes: '/project-items/edit-requests/respond-changes',
    },
    product_tender: {
      apply_tender_item: '/project-items/apply-tender-item',
    },
  },
  vendors: {
    create: '/vendors/create',
    update: '/vendors/update',
    list: '/vendors',
    respond_request: '/vendors/respond-request',
  },
  operators: {
    create: '/operators/create',
  },
  donors: {
    create: '/donors/create',
  },
  evaluators: {
    create: '/evaluators/create',
    mosque_maintenance: '/mosque-maintenance',
  },
  settings: {
    user: {
      edit_account_info: '/users/edit-account-info',
      edit_profile: '/users/edit-profile',
    },
    vendor: {
      edit_account_info: '/vendors/edit-account-info',
    },
    operator: {
      edit_account_info: '/operators/edit-account-info',
      delete_operator: '/operators/delete',
    },
    donor: {
      edit_account_info: '/donors/edit-account-info',
      delete_donor: '/donors/delete',
    },
    evaluator: {
      edit_account_info: '/evaluators/edit-account-info',
      delete_evaluator: '/evaluators/delete',
    },
  },
  tickets: {
    room: {
      messages: '/messages/rooms',
    },
  },
  geoCordinates: 'https://nominatim.openstreetmap.org/reverse.php',
  country: 'https://countriesnow.space/api/v0.1/countries/iso',
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  maintenance_request: {
    create_submission_task: '/maintenance-submission-task/create',
  },
  volunteers: {
    create_campaign_task: '/volunteers/campaign-task/create',
    edit_campaign_task: '/volunteers/campaign-task/edit',
  },
  blog: {
    create: '/blog/create',
    edit: '/blog/update',
    delete: '/blog/delete',
  },
  entity_area: {
    governorates: {
      create: '/region-management/governorates/create',
      edit: '/region-management/governorates/edit',
      delete: '/region-management/governorates/delete',
    },
    regions: {
      create: '/region-management/regions/create',
      edit: '/region-management/regions/edit',
      delete: '/region-management/regions/delete',
    },
  },
};
