export const ar = {
  campaign: {
    cant_delete: 'لا يمكن حذف هذه الحملة! إما أنه لم يتم رفضها بعد / أو تم التبرع بها!',
  },
  evaluator: {
    cant_delete: `لا يمكن حذف المُقيِّم لأنه قام بتطبيق الطلبات`,
  },
  transactions: {
    delete_donor_has_donations: `لا يمكن حذف المانح ، لأنه يحتوي على تاريخ التبرعات`,
  },
  ticket: {
    not_allowed_to_resolve: `لا يُسمح لك بحل هذه التذكرة`,
  },
};
