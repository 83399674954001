export const ar = {
  button: {
    view: 'عرض',
    delete_email: 'حذف الإيميل',
    edit_email: 'تعديل الإيميل',
  },

  headers: {
    sender_email: 'إيميل المرسل',
    name: 'الاسم',
    sent_at: 'تاريخ الإرسال',
  },
  title: {
    all_emails: 'كل الإيميلات',
  },
  form: {
    email: {
      label: 'البريد الإلكتروني',
    },
    senderEmail: {
      label: 'إيميل المرسل',
    },
    name: {
      label: 'الاسم',
    },
    location: {
      label: 'الموقع',
    },
    how_did_you_find: {
      label: 'كيف وصلت إلينا',
    },
    suggestion: {
      label: 'الاقتراحات',
    },
    upload: {
      label: 'الرجاء تحميل أي ملفات تساعدنا على فهم رغباتك',
    },
    buttons: {
      submit: 'إرسال النموذج',
    },
  },
};
