export const ar = {
  campaign_task: {
    not_found: 'لم يتم العثور على المهمة',
  },
  campaign: {
    not_found: 'لم يتم العثور على الحملة',
  },
  evaluator: {
    not_found: 'لم يتم العثور على المُقيِّم',
  },
  project: {
    not_found: 'لم يتم العثور على المسجد',
    operator_not_found: 'لم يتم العثور على مكتب  الإدارة',
  },
  ticket: {
    not_found: ' لم يتم العثور على التذكرة',
    log: {
      not_found_after_saving: 'سجل التذاكر غير موجود بعد التخزين',
    },
  },
};
