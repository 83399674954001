export const en = {
  campaign_task: {
    not_found: 'Task not found!',
  },
  campaign: {
    not_found: 'Campaign not found!',
  },
  evaluator: {
    not_found: 'Evaluator Not Found',
  },
  project: {
    not_found: 'Project not found!',
    operator_not_found: 'Project operator not found!',
  },
  ticket: {
    not_found: 'Ticket not found!',
    log: {
      not_found_after_saving: 'Ticket log not found after saving!',
    },
  },
};
