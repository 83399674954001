export const en = {
  dashboard: 'Dashboard',
  statistics: 'Statistics',
  project: {
    title: 'Projects',
    all_project: 'All Project',
    add_project: 'Add Project',
    my_projects: 'My Projects',
    details: 'Details Project',
    edit: 'Project Edit',
    edit_request: 'Project Edit Request',
    edit_project: 'Edit Project',
    delete_project: 'Delete Project',
    project_requests: 'Project Requests',
    project_discussion: 'Project Discussion',
  },
  campaign: {
    title: 'Campaigns',
    all_campaign: 'All Campaign',
    add_campaign: 'Add Campaign',
    my_campaigns: 'My Campaigns',
    details: 'Campaign Details',
    campaign_discussion: 'Campaign Discussion',
    latest_comments: 'Latest Comments',
    no_comments: 'No Comments',
    anonymous_user: 'Anonymous',
    reply: 'Reply',
    delete: 'Delete',
    edit_campaign: 'Edit Campaign',
    payment_history: 'Payment History',
    task_volunteers: 'Task and Volunteers',
    new_ticket: 'New Ticket',
    delete_campaign: 'Delete Campaign',
    edit_request: 'Campaign Edit Request',
    donor_list_campaign: 'Donor List of Campaign',
    donor_list_project: 'Donor List of Project',
    vendor_list_campaign: 'List of Campaigns',
    vendor_favorites_campaign: 'Favorite Campaigns',
    status: 'Campaign Status',
    vendor_requests: 'Vendor Requests',
    detail_vendor_request: 'Detail Vendor Requests',
    vendor_lists: 'Vendor Lists',
    chat_with_vendor: 'Chat with vendor',
    send_back_requests: 'Send Back Requests',
    send_it_back_to: 'Send it back to the',
    detail_send_back_requests: 'Detail Send Back Requests',
  },
  marketplace: {
    title: 'Marketplace',
    all_item: 'All Items',
    add_item: 'Add Item',
    details: 'Project Item Details',
    edit_item: 'Edit Item',
    edit_request_item: 'Edit Request',
    donor_list_item: 'Donor List of Project Items',
    product_tender: 'Product Tender',
    detail_product_tender: 'Detail Product Tender',
    all_vendor_tender_request: 'All Vendor Tender Requests',
    detail_vendor_tender_request: 'Detail Vendor Tender Requests',
  },
  donors: {
    title: 'Donors',
    add_donor: 'Add donor',
    all_donor: 'All Donors',
    donor_overview: 'Donor Overview',
    update_donor: 'Edit Donor',
    major_donors: 'Major Donors',
    major_donor_requests: 'Major Donor Requests',
    detail_major_donor_request: 'Detail Major Donor Request',
  },
  vendors: {
    title: 'Vendors',
    all_vendor: 'All Vendor',
    vendor_requests: 'Vendor Requests',
    add_vendor: 'Add New Vendor',
    detail_vendor: 'Vendor Detail',
    detail_requests_vendor: 'Vendor Request Detail',
    milestone_progress: 'Milestone Progress',
    milestone_invoices: 'Milestone Progress Invoices',
    payment_history_progress: 'Payment History Progress',
    edit: 'Edit Vendor',
    add_send_back_request_campaign: 'Send Back to Operator',
    nonprofit_organizations: 'Nonprofit Organizations',
  },
  operators: {
    title: 'Operators',
    all_operator: 'All Operators',
    add_operator: 'Add New Operator',
    update_operator: 'Edit Operator',
    detail_operator: 'Detail Operator',
  },
  evaluators: {
    title: 'Evaluators',
    maintenance_requests: `Maintenance's Evaluator's requests`,
    evaluators_request: `Evaluator's request`,
    all_evaluator: 'All Evaluators',
    add_evaluator: 'Add New Evaluator',
    update_evaluator: 'Edit Evaluator',
    details: `Evaluator's details`,
    chat_with_super_admin: 'Chat with super admin',
    chat_with_evaluator: 'Chat with evaluator',
  },
  mosques: {
    add_mosque: 'Add Mosque To Maintenance',
    all_mosque_directories: 'All Mosque Directories',
    detail_mosque_directories: 'Details Mosque Directories',
    add_directories: 'Add Mosque Directories',
  },
  tickets: {
    title: 'Tickets',
    all_ticket: 'All Ticket',
    detail_ticket: 'Detail Ticket',
  },
  directories: 'Directories',
  maintenance_request: 'Maintenance Request',
  community: 'Community',
  blogs: {
    title: 'Blogs',
  },
  items: 'Items',
  request: 'Requests',
  more_options: 'More Options',
  select_your_options: 'Select Your Options',
  operator_page: 'Operator Page',
  campaign_public_page: "Campaign's Public Page",
  parent_project_page: 'Parent Project Page',
  project_public_page: "Project's Public Page",
  logout: 'Logout',
  settings: {
    title: 'Settings',
    account_info: 'Account Info',
    security_settings: 'Security Settings',
    my_cards: 'My Cards',
    public_profile: 'Public Profile Settings',
    delete_account: 'Delete Account',
    profile_settings: 'Profile Settings',
  },
  emails: {
    title: 'Emails',
    details: 'Email details',
  },
  entity_area: {
    title: 'Entity Area',
    governorates: 'Governorates',
    regions: 'Regions',
  },
  major_donors: {
    title: 'Major Donors',
    all_major_donors: 'All Major Donors',
    add_major_donor: 'Add Major Donor',
    overview: 'Major Donor Overview',
  },
};
