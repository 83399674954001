export const en = {
  caption: {
    add_new_ticket: 'Add New Ticket',
    new_discussion: 'New Discussion',
    latest_tickets: 'Latest Tickets',
    loading: 'Loading ...',
    empty: 'Empty',
    notifications: 'Notifications',
    ticket_update_status: 'Ticket Update Status',
  },
  button: {
    new_ticket: 'New Ticket',
    send_ticket: 'Send Ticket',
    send_message: 'Send',
    reload_data: 'Reload Data',
    confirm: 'Confirm',
    update_status: 'Update Status',
    cancel: 'Cancel',
  },
  form: {
    discussion_for: {
      label: 'Discussion For',
      required: 'You must select type for send a ticket',
    },
    selected_id: {
      label: 'Select Item',
      required: 'You must select item for send a ticket',
    },
    name: {
      label: 'Name',
    },
    department: {
      label: 'Department',
    },
    last_update: {
      label: 'Last Update',
    },
    condition: {
      label: 'Condition',
    },
    name_of_ticket: {
      label: 'Name of Ticket',
      required: 'Name of Ticket is required!',
    },
    message_of_ticket: {
      label: 'Message',
      required: 'You must input what topic to be discussion!',
    },
    selected_donor: {
      label: 'Select Donor',
    },
    message: {
      label: 'Enter Message',
      required: 'You must input your message!',
    },
    ticket_status: {
      label: 'Ticket Status',
      required: 'Ticket Status is required!',
    },
    forwarded_to: {
      label: 'Forwarded to ...',
      required: 'You must select the user!',
    },
    assign_to: {
      label: 'Assign to ...',
      required: 'You must select the user!',
    },
  },
};
