export const en = {
  form: {
    user_id: {
      label: 'Select Donor',
      required: 'You must select one donor!',
    },
    balance: {
      label: 'Balance',
      required: 'You must fill the balance!',
      min_1: 'You must fill the balance be greater than 0',
    },
  },
};
