export const en = {
  ticket: {
    ticket_created: 'New Ticket Created',
    new_message: 'New Ticket message',
    ticket_closed: 'Ticket Closed',
    ticket_forwarded: 'New Forwarded Ticket',
    new_applied_request_message: 'New Applied Request Message',
    ticket_assigned: 'New Ticket Assigned',
    ticket_resolved: 'Ticket Resolved',
  },
  donation: {
    donation_received: 'New Donation Received',
  },
  apply_request: {
    new_message: 'New Vendor Applied Request Message',
  },
  project: {
    created_project_to_operator: 'New Project Created',
    need_approval_from_operator: 'Project Need Approval',
    respond: {
      approved_project: 'Project Approved',
      rejected_project: 'Project Rejected',
    },
    ask: {
      changes: 'Project Changes Requested',
    },
  },
  campaign: {
    created_campaign_to_operator: 'New Campaign Created',
    need_approval_from_operator: 'Campaign Need Approval',
    respond: {
      approved_campaign: 'Campaign Approved',
      rejected_campaign: 'Campaign Rejected',
    },
    ask: {
      changes: 'Campaign Changes Requested',
    },
    vendor_request: 'New Vendor Apply Campaign Request',
    vendor_request_approved: 'New accepted apply campaign request',
    vendor_request_rejected: 'New rejected apply campaign request',
  },
  project_item: {
    created_project_item_to_operator: 'New Marketplace Item Created',
    need_approval_from_operator: 'Marketplace Item Need Approval',
    assign_vendor: 'New Marketplace Item Assigned to you',
    respond: {
      approved_project_item: 'Marketplace Item Approved',
      rejected_project_item: 'Marketplace Item Rejected',
    },
    ask: {
      changes: 'Marketplace Item Changes Requested',
    },
  },
  milestone: {
    payment: {
      upload_receipt: 'New Milestone Payment Receipt',
    },
    process: {
      new_process: 'New Milestone Process',
      accept_request: 'New Accept Milestone Process Request',
      reject_request: 'New Reject Milestone Process Request',
    },
  },
};
