export const en = {
  title: {
    project_marketplace: 'Project Marketplace',
    status: 'Status',
    tender_request_status: 'Request Status',
    request_detail: 'Request Detail',
    technical_offer: 'Technical Offer',
    financial_offer: 'Financial Offer',
    no_files_uploaded: 'No Files Uploaded',
  },
  button: {
    add_new_item: 'Add New Item',
    submit: 'Submit',
    apply_tender_request: 'Apply Tender Request',
    approve_request: 'Approve Request',
    reject_request: 'Reject Request',
    view_detail: 'View Detail',
  },
  form: {
    name: {
      label: "Items's Name",
      required: "Item's name is required!",
    },
    amount_needed: {
      label: 'Total Needed',
      min_1: 'Must be greater than 1',
      required: 'Total needed is required!',
    },
    provide_items: {
      label: 'Provide Items',
      required: 'Provide item is required!',
    },
    price: {
      label: 'Price',
      min_1: 'Must be greater than 1',
      required: 'Price is required!',
    },
    vendor_type: {
      label: 'Select Vendor Type',
      required: 'Vendor type is required!',
      options: {
        MANUAL_VENDOR: 'Manual Vendor',
        WITHOUT_VENDOR: 'Without Vendor',
        PRODUCT_TENDER: 'Product Tender',
      },
    },
    vendor_id: {
      label: 'Select Vendor',
      required: 'Vendor is required!',
    },
    specification: {
      label: 'Insert the Specification',
      label_2: 'Specification',
      required: 'Specification is required!',
    },
    start_date: {
      label: 'Start Date',
      required: 'Start date is required!',
    },
    deadline_date: {
      label: 'Deadline Date',
      required: 'Deadline date is required!',
    },
    delivery_date: {
      label: 'Delivery Date',
      required: 'Delivery date is required!',
    },
    insurance_details: {
      label: 'Insurance Details',
      required: 'Insurance details is required!',
    },
    contract_agreement: {
      label: 'Contract Agreement',
      required: 'Contract agreement is required!',
      max_1: 'Must be less than 1',
    },
    contract_signature: {
      label: 'Contract Signature',
      required: 'Contract signature is required!',
      max_1: 'Must be less than 1',
    },
    proposed_price: {
      label: 'Proposed Price',
      required: 'Proposed price is required!',
      min_1: 'Must be greater than 1',
    },
    proposed_amount_needed: {
      label: 'Proposed Item Needed',
      required: 'Proposed item needed is required!',
      min_1: 'Must be greater than 1',
    },
    technical_offer: {
      label: 'Technical Offer',
      required: 'Technical Offer is required!',
      max_1: 'Must be less than 1 file',
      max_5: 'Must be less than 5 files',
    },
    financial_offer: {
      label: 'Financial Offer',
      required: 'Financial Offer is required!',
    },
  },
};
