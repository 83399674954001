export const ar = {
  caption: {
    title: 'مَلَفّ',
    create: 'إنشاء محفظة جديدة',
    detail_portfolio: 'تفاصيل الحافظة',
    status: {
      active: 'نشيط',
      inactive: 'غير نشط',
    },
    add_new_portfolio: 'أضف محفظة مانحة جديدة',
    confirmation_delete_portfolio: 'هل أنت متأكد من أنك تريد حذف هذه المحفظة؟',
    donor_information: 'معلومات المانح',
    portfolio_information: 'معلومات الحافظة',
    select_major_donors: 'حدد أحد المانحين الرئيسيين',
    select: 'يختار',
    contact_information: 'معلومات الاتصال',
    realtime_balance: 'توازن في الوقت الحقيقي',
    campaign: {
      all_campaigns: 'جميع الحملات',
      currently_campaigns: 'الحملات حاليا',
      finished_campaigns: 'الحملات الانتهاء',
    },
    search: 'يبحث',
    sort_by: 'فرز حسب',
    latest: 'أحدث',
    oldest: 'أقدم',
    popular: 'شائع',
  },
  button: {
    share_portfolio: 'حافظة مشاركة',
    delete_portfolio: 'حذف محفظة',
    create_portfolio: 'إنشاء محفظة جديدة',
    create_donor_portfolio: 'إنشاء محفظة مانحة',
    continue: 'يكمل',
    edit_portfolio: 'تحرير محفظة',
    add_endowment_amount: 'إضافة مبلغ الوقف',
    periodic_report: 'تقرير دوري',
    view_transaction_history: 'عرض تاريخ المعاملة',
    share: 'يشارك',
    delete: 'يمسح',
  },
  form: {
    portfolio_name: {
      label: 'اسم المحفظة',
      required: 'مطلوب اسم المحفظة',
    },
    creation_date: {
      label: 'تاريخ الخلق',
      required: 'مطلوب تاريخ تم إنشاؤه',
    },
    balance: {
      label: 'توازن',
      required: 'مطلوب التوازن',
      min_1: 'يجب أن يكون أكبر من 1',
    },
    status: {
      label: 'حالة',
      required: 'الحالة مطلوبة',
    },
    type: {
      label: 'يكتب',
      required: 'نوع الحافظة مطلوب',
    },
    region: {
      label: 'منطقة',
      required: 'المنطقة مطلوبة',
    },
    full_name: {
      label: 'الاسم الكامل',
      required: 'مطلوب الاسم الكامل',
    },
    phone_number: {
      label: 'رقم التليفون',
      required: 'رقم الهاتف مطلوب',
    },
    email: {
      label: 'بريد إلكتروني',
      required: 'البريد الإلكتروني مطلوب',
    },
    selected_reference: {
      label: 'المشروع / الحملة المراد دعمها',
      required: 'هذا الحقل لا يكون فارغا',
    },
  },
};
