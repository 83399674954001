export const en = {
  caption: {
    title: 'Portfolios',
    create: 'Create New Portfolio',
    detail_portfolio: 'Portfolio Details',
    status: {
      active: 'Active',
      inactive: 'Inactive',
    },
    add_new_portfolio: 'Add New Donor Portfolio',
    confirmation_delete_portfolio: 'Are You Sure You Want To Delete This Portfolio?',
    donor_information: 'Donor Information',
    portfolio_information: 'Portfolio Information',
    select_major_donors: 'Select One Of The Major Donors',
    select: 'Select',
    contact_information: 'Contact Information',
    realtime_balance: 'Real-Time Balance',
    campaign: {
      all_campaigns: 'All Campaigns',
      currently_campaigns: 'Currently Campaigns',
      finished_campaigns: 'Finished Campaigns',
    },
    search: 'Search ...',
    sort_by: 'Sort By',
    latest: 'Latest',
    oldest: 'Oldest',
    popular: 'Popular',
  },
  button: {
    share_portfolio: 'Share Portfolio',
    delete_portfolio: 'Delete Portfolio',
    create_portfolio: 'Create New Portfolio',
    create_donor_portfolio: 'Create Donor Portfolio',
    continue: 'Continue',
    edit_portfolio: 'Edit Portfolio',
    add_endowment_amount: 'Add Endowmnet Amount',
    periodic_report: 'Periodic Report',
    view_transaction_history: 'View Transaction History',
    share: 'Share',
    delete: 'Delete',
  },
  form: {
    portfolio_name: {
      label: 'Portfolio Name',
      required: 'Porfolio Name is required!',
    },
    creation_date: {
      label: 'Creation Date',
      required: 'Created date is required!',
    },
    balance: {
      label: 'Balance',
      required: 'Balance is required!',
      min_1: 'Must be greater than 1',
    },
    status: {
      label: 'Status',
      required: 'Status is required!',
    },
    type: {
      label: 'Type',
      required: 'Portofolio type is required!',
    },
    region: {
      label: 'Region',
      required: 'Region is required!',
    },
    full_name: {
      label: 'Full Name',
      required: 'Full Name is required!',
    },
    phone_number: {
      label: 'Phone Number',
      required: 'Phone Number is required!',
    },
    email: {
      label: 'Email',
      required: 'Email is required!',
    },
    selected_reference: {
      label: 'Project / Campaign to be Supported',
      required: 'This field is not to be empty',
    },
  },
};
