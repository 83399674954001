export const en = {
  governorates: {
    success_delete: 'Governorate has been deleted!',
  },
  regions: {
    success_delete: 'Region has been deleted!',
  },
  product_tender: {
    success_find_many_vendor_request: 'Vendor request list is successfully fetched',
  },
};
