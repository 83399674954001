export const ar = {
  title: 'المتطوعون',
  caption: {
    all_volunteer: 'كل المتطوعين',
    detail_volunteer: 'تفاصيل التطوع',
    contact_us: 'اتصل بنا',
    analities: 'ملفات التحليلات الخاصة بك',
    campaign_task_for_volunteer: 'مهام الحملة للمتطوعين',
    all_campaign_task: 'طلبات التطوع من المساجد والفروع',
    detail_campaign_task: 'مهمة الحملة التفصيلية',
    edit_campaign_task: 'تحرير مهمة الحملة',
    volunteer_reports: 'تقارير المتطوعين',
  },
  button: {
    add_new_task: 'أضف مهمة تطوعية',
    submit_task: 'إرسال المهمة',
    edit_campaign_task: 'تحرير المهمة',
  },
  form: {
    name: {
      label: 'اسم المتطوع',
      required: 'مطلوب اسم المتطوع',
    },
    request_campaign: {
      label: 'طلب حملة',
    },
    approve_campaign: {
      label: 'الموافقة على الحملة',
    },
    job: {
      label: 'الوظيفة',
      required: 'الوظيفة مطلوبة',
    },
    abilities: {
      label: 'قدراتك',
      required: 'قدراتك مطلوبة',
      max: 'مطلوب 255 حرفاً كحد أقصى',
    },
    about_free_time: {
      label: 'أخبرنا عن وقت فراغك في أيامك',
      required: 'هذه الخانة مطلوبة',
    },
    how_to_find: {
      label: 'كيف وجدت مساجدنا',
      required: 'هذه الخانة مطلوبة',
    },
    country: {
      label: 'الدولة',
      required: 'الدولة مطلوبة',
    },
    region: {
      label: 'المنطقة',
      required: 'المنطقة المحيطة مطلوبة',
    },
    governorate: {
      label: 'المحافظة',
      required: 'المحافظة مطلوبة',
    },
    selection: {
      options: {
        advertisements: 'الإعلانات',
        event_or_conferences: 'الأحداث أو المؤتمرات المتعلقة بالعمل',
        referal_industry: 'إحالة من شخص ما في الصناعة',
        online_platforms: 'المنصات عبر الإنترنت',
        other: 'آخر',
      },
    },
    analities: {
      max: 'كحد أقصى 3 ملفات',
    },
    address: {
      label: 'موقعك',
    },
    task_name: {
      label: 'اسم المهمة',
      required: 'اسم المهمة مطلوب',
    },
    assigned_volunteers: {
      label: 'متطوعون مخصصون',
      required: 'مطلوب متطوعون مخصصون',
    },
    requested_volunteers: {
      label: 'متطوعون طلبوا',
      required: 'المتطوعون مطلوب',
    },
    campaign_id: {
      label: 'الحملة الأساسية',
      required: 'الحملة الأساسية مطلوبة',
    },
    available_seats: {
      label: 'المقاعد المتاحة',
    },
    enrollment_type: {
      label: 'نوع التسجيل',
      required: 'نوع التسجيل مطلوب',
      options: {
        self_enrolled: 'مسجّل ذاتيا',
        auto_enrolled: 'تلقائيا',
        invited_enrollment: 'مسجل مدعو',
        mandatory_enrollment: 'الالتحاق الإلزامي',
        optin_enrollment: 'الالتحاق بالإشتراك',
        conditional_enrollment: 'التسجيل المشروط',
        group_enrollment: 'تسجيل المجموعة',
        referral_enrollment: 'تسجيل الإحالة',
      },
    },
    gender: {
      label: 'جنس',
      min_1: 'اختر خياراً واحداً على الأقل',
      options: {
        male: 'ذكر',
        female: 'أنثى',
      },
    },
    reason_to_pay: {
      label: 'سبب الدفع',
      required: 'يجب أن تملأ سبب الدفع',
    },
    is_remote: {
      label: 'هو بعيد؟',
      required: 'يجب عليك تحديد خيار واحد',
      yes: 'نعم',
      no: 'لا',
    },
    is_urgent: {
      label: 'إنه أمر عاجل؟',
    },
    available_disabilities: {
      label: 'متاح للإعاقة؟',
    },
    needs_interview: {
      label: 'يحتاج إلى مقابلة؟',
    },
    task_details: {
      label: 'تفاصيل المهمة',
      required: 'تفاصيل المهمة مطلوبة',
      max: '500 حرف كحد أقصى',
    },
    required_skills: {
      label: 'المهارات المطلوبة',
      required: 'المهارات مطلوبة',
    },
    required_profession: {
      label: 'المهنة مطلوبة',
      required: 'المهنة مطلوبة',
    },
    support_provider: {
      label: 'مزود الدعم',
      required: 'مزود الدعم مطلوب',
    },
    required_age: {
      label: 'العمر المطلوب',
      required: ' العمر مطلوب',
    },
    benefits: {
      label: 'الفوائد لك',
      required: 'الفوائد مطلوبة',
    },
    date_assigned: {
      label: 'التاريخ المعين',
    },
    date_requested: {
      label: 'التاريخ مطلوب',
    },
    task_done_before: {
      label: 'المهمة المنجزة من قبل',
    },
  },
};
