import { ar as blog } from '../blog/translation/ar';
import { ar as donor } from '../donor/translation/ar';
import { ar as email } from '../email/translation/ar';
import { ar as admin } from '../admin/translation/ar';
import { ar as ticket } from '../ticket/translation/ar';
import { ar as vendor } from '../vendor/translation/ar';
import { ar as project } from '../project/translation/ar';
import { ar as operator } from '../operator/translation/ar';
import { ar as campaign } from '../campaign/translation/ar';
import { ar as settings } from '../settings/translation/ar';
import { ar as evaluator } from '../evaluator/translation/ar';
import { ar as volunteers } from '../volunteer/translation/ar';
import { ar as portfolios } from '../portfolios/translation/ar';
import { ar as entity_area } from '../entity-area/translation/ar';
import { ar as major_donors } from '../major-donors/translations/ar';
import { ar as marketplace } from '../marketplace-item/translation/ar';
import { ar as maintenance_request } from '../maintenance/translations/ar';

export const ar = {
  blog,
  donor,
  email,
  admin,
  ticket,
  vendor,
  project,
  operator,
  campaign,
  settings,
  evaluator,
  volunteers,
  portfolios,
  marketplace,
  entity_area,
  major_donors,
  maintenance_request,
};
