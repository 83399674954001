export const en = {
  volunteer: {
    success_applied: 'Volunteer applied to task succeessfully!',
    success_applied_responded: 'Volunteer apply responded succeessfully!',
  },
  campaign_edit: {
    ask_changes: 'Your Changes Request Successfully Submitted!',
    respond_changes: 'Campaign Successfully Edited!',
  },
  governorates: {
    success_delete: 'Governorate has been deleted!',
    success_create: 'New governorate has been created!',
    success_update: 'Governorate has been updated!',
  },
  regions: {
    success_create: 'New region has been created!',
    success_update: 'Region has been updated!',
  },
  mosque_directories: {
    success_created_directories: 'New mosque directories has been created successfully!',
  },
  product_tender: {
    success_vendor_apply: 'Your request successfully submitted!',
    success_vendor_responded: {
      ACCEPT: 'Vendor request is accepted!',
      REJECT: 'Vendor request is rejected!',
    },
  },
};
