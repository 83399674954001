export const ar = {
  governorates: {
    success_delete: 'تم حذف المحافظة',
  },
  regions: {
    success_delete: 'تم حذف المنطقة المحيطة',
  },
  product_tender: {
    success_find_many_vendor_request: 'تم جلب قائمة طلبات البائع بنجاح',
  },
};
